import { DeleteOutlined } from '@ant-design/icons'
import { Card, Button, Popconfirm, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { GroupProgram } from '../../types'
import { GroupProgramsApi } from '../api'
import { GroupProgramForm } from './forms/GroupProgramForm'

export const GroupProgramEditPage: React.FC = () => {
  const navigate = useNavigate()
  const { id = '' } = useParams()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [groupProgram, setGroupProgram] = useState<GroupProgram>()

  const fetchGroupProgram = async () => {
    if (id === 'new') return
    setLoading(true)
    const data = await GroupProgramsApi.get(id)
    setGroupProgram(data)
    setLoading(false)
  }

  const handleDeleteTermsAndConditions = async (record: GroupProgram) => {
    await GroupProgramsApi.destroy(record.id)
    notification.success({
      message: t('general.deleteSuccess'),
    })
    navigate('/groupPrograms')
  }

  useEffect(() => {
    fetchGroupProgram()
  }, [])

  return (
    <>
      <Card
        title={t('groupPrograms.editGroupPrograms')}
        loading={loading}
        extra={[
          groupProgram && (
            <Popconfirm
              title={`${t('general.areYouSure')}`}
              onConfirm={() =>
                groupProgram && handleDeleteTermsAndConditions(groupProgram)
              }
              okText={`${t('general.yes')}`}
              cancelText={`${t('general.no')}`}
            >
              <Button key="delete" danger icon={<DeleteOutlined />}>
                {t('actions.delete')}
              </Button>
            </Popconfirm>
          ),
        ]}
      >
        <GroupProgramForm
          groupProgram={groupProgram}
          refetch={fetchGroupProgram}
        />
      </Card>
    </>
  )
}
