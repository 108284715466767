import { GroupSlot } from '../../types'
import { apiClient } from './ApiClient'

export const getAll: () => Promise<GroupSlot[]> = async () => {
  const response = await apiClient.get<GroupSlot[]>('/group_slots')
  return response.data
}

export const get: (id: string) => Promise<GroupSlot> = async (id) => {
  const response = await apiClient.get<GroupSlot>(`/group_slots/${id}`)
  return response.data
}

export const create: (data: {
  GroupSlot: Omit<GroupSlot, 'id'>
}) => Promise<GroupSlot> = async (data) => {
  const response = await apiClient.post<GroupSlot>('/group_slots', data)
  return response.data
}

export const update: (
  id: string,
  data: { GroupSlot: Omit<GroupSlot, 'id'> }
) => Promise<GroupSlot> = async (id, data) => {
  const response = await apiClient.put<GroupSlot>(`/group_slots/${id}`, data)
  return response.data
}

export const destroy = async (id: string) => {
  const response = await apiClient.delete<GroupSlot>(`/group_slots/${id}`)
  return response.data
}
