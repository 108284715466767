import React, { useEffect } from 'react'

import { BrowserRouter as Router } from 'react-router-dom'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { createConsumer } from '@rails/actioncable'
import 'antd/dist/reset.css'
import './App.css'
import { AuthProvider } from './modules'
import enTranslation from './locales/en.json'
import { ConfigProvider } from 'antd'
import { AppRouter } from './routers/AppRouter'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: enTranslation,
      },
    },
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

function App() {
  // const consumer = createConsumer(
  //   `ws://${process.env.REACT_APP_API_URL?.replace('https://', '').replace(
  //     'http://',
  //     ''
  //   )}/cable?token=${localStorage.getItem('accessToken')}`
  // )

  // consumer.subscriptions.create(
  //   { channel: 'UserChannel' },
  //   {
  //     received(data) {
  //       console.log('Received:', data)
  //     },
  //   }
  // )
  // useEffect(() => {
  //   const socket = new WebSocket(
  //
  //   )

  //   socket.onopen = () => {
  //     console.log('WebSocket connected')
  //     socket.send(
  //       JSON.stringify({
  //         command: 'subscribe',
  //         identifier: JSON.stringify({ channel: 'UserChannel' }),
  //       })
  //     )

  //     socket.onmessage = (event) => {
  //       console.log('Message received:', event.data)
  //     }
  //   }

  //   socket.onerror = (event) => {
  //     console.error('WebSocket error:', event)
  //   }

  //   socket.onclose = (e) => {
  //     // connection closed
  //     console.log(e.code, e.reason)
  //   }
  // }, [])

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Montserrat',
          },
        }}
      >
        <Router>
          <AuthProvider>
            <AppRouter />
          </AuthProvider>
        </Router>
      </ConfigProvider>
    </>
  )
}

export default App
