import { Group } from '../../types'
import { apiClient } from './ApiClient'

export const getAll: () => Promise<Group[]> = async () => {
  const response = await apiClient.get<Group[]>('/groups')
  return response.data
}

export const get: (id: string) => Promise<Group> = async (id) => {
  const response = await apiClient.get<Group>(`/groups/${id}`)
  return response.data
}

export const create: (data: {
  Group: Omit<Group, 'id'>
}) => Promise<Group> = async (data) => {
  const response = await apiClient.post<Group>('/groups', data)
  return response.data
}

export const update: (
  id: string,
  data: { Group: Omit<Group, 'id'> }
) => Promise<Group> = async (id, data) => {
  const response = await apiClient.put<Group>(`/groups/${id}`, data)
  return response.data
}

export const destroy = async (id: string) => {
  const response = await apiClient.delete<Group>(`/groups/${id}`)
  return response.data
}
