import React from 'react'
import { Button, Popconfirm, Table, TableColumnType, notification } from 'antd'
import { GroupProgram } from '../../types'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../helpers'
import { Link } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import { GroupProgramsApi } from '../api'

type GroupProgramsTableProps = {
  groupPrograms: GroupProgram[]
  refetch: () => void
}

export const GroupProgramsTable: React.FC<GroupProgramsTableProps> = ({
  groupPrograms,
  refetch,
}) => {
  const { t } = useTranslation()

  const handleDeleteGroupProgram = async (record: GroupProgram) => {
    await GroupProgramsApi.destroy(record.id)
    notification.success({
      message: t('general.deleteSuccess'),
    })
    refetch()
  }

  const columns: TableColumnType<GroupProgram>[] = [
    {
      title: t('general.name'),
      dataIndex: 'name',
      key: 'name',
      render: (name, item) => (
        <Link to={`/groupPrograms/${item.id}`}>{name}</Link>
      ),
    },
    {
      title: t('general.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => formatDateTime(updatedAt),
    },
    {
      title: t('general.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => formatDateTime(createdAt),
    },
    {
      key: 'actions',
      render: (_, item) => (
        <Popconfirm
          title={`${t('general.areYouSure')}`}
          onConfirm={() => handleDeleteGroupProgram(item)}
          okText={`${t('general.yes')}`}
          cancelText={`${t('general.no')}`}
        >
          <Button key="delete" danger icon={<DeleteOutlined />}>
            {t('actions.delete')}
          </Button>
        </Popconfirm>
      ),
    },
  ]

  return (
    <>
      <Table dataSource={groupPrograms} columns={columns} rowKey="id" />
    </>
  )
}
